<div *ngIf="displayBanner" class="environment-banner alert-danger default-environment-banner">
  <div class="container">
    <strong>This is a test environment.</strong> Please proceed to <a href="{{domain}}" rel="noopener noreferrer">{{domain}}</a> and remove all bookmarks or references to this site.
  </div>
</div>
<div class="environment-banner alert-danger">
  <div class="container">
    <span>
      <strong>
        The Israel-Hamas Armed Conflict became a known event as of October 7, 2023.  Until further notice, we are not accepting sales of policies covering known travel to Israel. Policies that become effective after October 7, 2023 will not provide coverage or accommodations for losses directly or indirectly resulting from this event.  Read our <a href="https://azcontent.us/alert/israel-hamas-armed-conflict " target="_blank" rel="noopener">Coverage Alert</a> for more details about policy coverage, accommodations, and exclusions related to this event.
      </strong>
    </span>
  </div>
</div>
