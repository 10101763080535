<footer class="container">
  <div *ngIf="!clp" class="footer-links" [style.background-color]="navbarColor">
    <div class="row container links-container">
      <div class="col-md-12 footer-links-container footer-links-container--nav">
        <div>
          <ul class="nav-links-container">
            <li *ngFor="let link of getActiveLinks()">
              <a *ngIf="!link.isExternal" class="footer-link" [routerLink]="[partnerName, link.route]" title="{{link.name}}" >{{link.name}}</a>
              <a *ngIf="link.isExternal" class="footer-link" href="{{link.route}}" target="_blank" title="{{link.name}}">{{link.name}}&nbsp;&nbsp;
                <span class="fa fa-external-link" aria-hidden="true"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-terms container">
    <div class="col-md-3 logos">
      <div class="logo-container d-md-none">
        <small>Provided by</small>
        <a [routerLink]="[partnerName, 'home']">
          <img class="logo" alt="Logo" [src]="agaLogoUrl">
        </a>
      </div>
    </div>
    <div class="col-md-12 footer-content">
      <p><strong>© 2023 Allianz Global Assistance. All Rights Reserved | </strong>
        <a style="color: #007bff;font-weight: bolder;" 
        onmouseover="this.style.color='#0056b3';this.style.textDecoration='underline';"
        onmouseout="this.style.color='#007bff';this.style.textDecoration='none';"  
        aria-label="Cookies" data-automation-id="footer-link" rel="noopener noreferrer"
          id="ot-sdk" 
          class="ot-sdk-show-settings">Do Not Sell or Share My Personal Information</a> 
          <strong>  |  </strong> <a id="privacyAnchor" style="color: #007bff;" 
          [routerLink]="[partnerName, 'legal', 'privacy']" rel="noopener"
          onmouseover="this.style.color='#0056b3';this.style.textDecoration='underline';"
          onmouseout="this.style.color='#007bff';this.style.textDecoration='none';"  >
            <strong>Privacy</strong></a>
          <strong>  |  </strong> <a style="color: #007bff;" 
          href="https://www.allianzworldwidepartners.com/usa/terms-and-conditions/terms_of_use" 
          target="_blank" rel="noopener"
          onmouseover="this.style.color='#0056b3';this.style.textDecoration='underline';"
          onmouseout="this.style.color='#007bff';this.style.textDecoration='none';">
          <strong>Terms of Use</strong></a>
      </p>
      <p [innerHtml]="footerContent"></p>
    </div>
    <div class="col-12">
      <a class="verisign" href="https://sealinfo.verisign.com/splash?form_file=fdf/splash.fdf&amp;dn=www.allianztravelinsurance.com&amp;lang=en"
        target="_blank" rel="noopener noreferrer">
        <img src="/assets/verisign.png" alt="verisign secured logo" title="">
      </a>
      <a href="https://www.facebook.com/AllianzTravelInsuranceUS" target="_blank" class="facebook fa-stack" rel="noopener" aria-label="Allianz Facebook">
        <span class="fa fa-circle fa-stack-2x"></span>
        <span class="fa fa-facebook fa-stack-1x fa-inverse"></span>
      </a>
      <a href="http://www.twitter.com/allianztravelus" target="_blank" class="twitter fa-stack" rel="noopener" aria-label="Allianz Twitter">
        <span class="fa fa-circle fa-stack-2x"></span>
        <span class="fa fa-twitter fa-stack-1x fa-inverse"></span>
      </a>
    </div>
  </div>
  <div class="version col-sm-12">
    <small>{{'v' + appVersion}}</small>
  </div>  
</footer>
