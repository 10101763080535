
import {take} from 'rxjs/operators';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { TimeoutService } from './shared/services/timeout.service';
import { AlertService } from './shared/alert/services/alert.service';
import { Observable ,  Subscription } from 'rxjs';
import { ContentManagementService } from './shared/services/content-management.service';
import { AppStateService } from './shared/services/app-state.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, RouterModule } from '@angular/router';
import { QuoteService } from './quote/services/quote.service';
import { Component, OnInit, Inject, Directive } from '@angular/core';
import * as _ from 'lodash';




import { NavigationEnum } from './components/navbar/navigation.enum';
import { OverlayModule } from '@angular/cdk/overlay';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { BlockScrollStrategy } from '@angular/cdk/overlay';
// import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { ComponentPortal } from '@angular/cdk/portal';
import { GoogleAnalyticsEventsService } from './shared/services/google-analytics-events.service';
import { Transaction, Product, Site, RuntimeEnvConfig } from './shared/models/index';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Favicons } from './favicons';
import { ConfigService } from './shared/services/config.service';
import { DomainTypeEnum } from './policy/models/domain-type.enum';
import { environment } from '../environments/environment';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { WarningBannerComponent } from './components/warning-banner/warning-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AlertComponent } from './shared/alert/alert.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterModule, MatProgressBarModule, AlertComponent, FooterComponent, LoadingIndicatorComponent, WarningBannerComponent, HeaderComponent, NavbarComponent]
})
export class AppComponent implements OnInit {

  private _agencyInfoSub: Subscription;
  private _timeoutSubscription: Subscription;
  private _routerSubscription: Subscription;
  private _partnerConfig: Site;
  private favicons: Favicons;
  public domain: string;
  globalNav: boolean;

  title = 'app';
  public showProgressBar = false;
  public config: RuntimeEnvConfig;
  public anchorPrivacyPath: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private timeoutService: TimeoutService,
    private appStateService: AppStateService,
    private ga: GoogleAnalyticsEventsService,
    private titleService: Title,
    favicons: Favicons,
    private configService: ConfigService,
    private location: Location,
    @Inject(DOCUMENT) private doc: any
  ) {
      this.favicons = favicons;
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const csid = params['csid'];

      if (csid) {
        this.appStateService.setCSID(csid);
        this.ga.push({ 'coSourceId': `${csid}` });
      }

      this.resetFavicon();
    });

    this._agencyInfoSub = this.appStateService.agencyInfoObservable$.pipe(
      take(1))
      .subscribe(config => {
        this.ga.push({ 'accam': `${config.accam}` });
        this.appendGaNode(config.domain);
        this.setTitle(config.browserTabDisplayName);

        this.configService.loadEnvConfig().then((res: RuntimeEnvConfig) => {
          this.config = res;
        }).catch(e => console.log(e));
      });

    this.initBaseRouterProgressBar();

    this._timeoutSubscription = this.timeoutService.timeoutObservable$.subscribe(result => {
      if (result === false) {
        this.handleTimeout();
      }
    });

    this._routerSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  public resetFavicon(): void {
    this.favicons.reset();
  }

  ngOnDestroy() {
    if (this._timeoutSubscription) {
      this._timeoutSubscription.unsubscribe();
    }
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
    if (this._agencyInfoSub) {
      this._agencyInfoSub.unsubscribe();
    }
  }

  private handleTimeout() {
    const partnerName: string = this.appStateService.getPartnerName();

    this.appStateService.clearAppState();
    this.appStateService.toggleActiveLink(NavigationEnum.MyPolicies, false);

    this.router.navigateByUrl(partnerName);
  }

  private initBaseRouterProgressBar() {
    this.router.events.subscribe(event => {
      this._partnerConfig = this.appStateService.getPartnerConfig();
      const partnerName: string = this.appStateService.getPartnerName();
      if (this._partnerConfig) {
        if (event instanceof NavigationStart) {
          this.showProgressBar = true;
          this.favicons.setIcon('none');
        } else if (event instanceof NavigationEnd) {
          this.globalNav = this.location.path().includes('privacy');
          this.ga.push({ 'event': 'page-view' });
          this.showProgressBar = false;
          this.domain = this.setDefaultUrl(this._partnerConfig.domain, partnerName);
          //privacy page
          localStorage.setItem("PARTNER_URL_PATH","/"+this._partnerConfig.name+"/legal/privacy");
          this.setFavIcon(this._partnerConfig.displayName);
        } else if (event instanceof NavigationCancel) {
          this.globalNav = this.location.path().includes('privacy');
          this.showProgressBar = false;
        }
      }
    });
  }

  private setDefaultUrl(domain, partnerName) {
    switch (domain) {
      case(DomainTypeEnum.Travel):
        return `${environment.etravelDomainUrl}/${partnerName}`;
      case(DomainTypeEnum.EventTicketProtection):
        return `${environment.eventTicketDomainUrl}/${partnerName}`;
      case(DomainTypeEnum.EventRegistration):
        return `${environment.eventRegistrationDomainUrl}/${partnerName}`;
      case(DomainTypeEnum.Session):
        return `${environment.sessionDomainUrl}/${partnerName}`;
      case(DomainTypeEnum.Season):
        return `${environment.seasonDomainUrl}/${partnerName}`;
    }
  }

  private setTitle(partnerName: string) {
    this.titleService.setTitle(partnerName);
  }

  public setFavIcon(name: string): void {
    name === 'Allianz' || name === 'Allianz Ticket' || name === 'Allianz Registration' ||
    name === 'Allianz Session' || name === 'Allianz Season'
      ? this.favicons.setIcon('allianz')
      : this.favicons.setIcon('partner');
  }

  // Append GTM script function to <head>
  private appendGaNode(domain: string) {
    const gtmId = this.ga.setGaContainerID(domain);

    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = `${environment.innerHTMLScript.replace("{gtmId}",gtmId)}`;
    const head = this.doc.getElementsByTagName('head');
    head[0].appendChild(s);
  }
}
