<mat-card appearance="outlined" class="widget-container">
  <div #widgetHeight>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li *ngIf="showQuoteTab === true" class="nav-item">
      <a class="nav-link" (click)="selectedTab = 1;onGetAQuoteClicked();" (mouseover)="hoveringTab = 1" (mouseout)="hoveringTab = null" [style.background-color]="(selectedTab === 1) ? tabHoverColor : nonActiveTabColor"
        id="quote-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="quote"
        [style.color]="(selectedTab === 1) ? '#fff' : '#000'" [ngClass]="{ 'active': selectedTab === 1}">Get a Quote</a>
    </li>
    <li *ngIf="showPolicyTab === true" class="nav-item">
      <a class="nav-link" (click)="selectedTab = 2;onManagePolicyClicked();" (mouseover)="hoveringTab = 2" (mouseout)="hoveringTab = null" [style.background-color]="(selectedTab === 2) ? tabHoverColor : nonActiveTabColor"
        id="manage-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="manage"
        [style.color]="(selectedTab === 2) ? '#fff' : '#000'" [ngClass]="{ 'active': selectedTab === 2}">Manage a Policy</a>
    </li>
    <li *ngIf="showClaimTab === true" class="nav-item">
      <a class="nav-link" (click)="selectedTab = 3;onClaimClicked();" (mouseover)="hoveringTab = 3" (mouseout)="hoveringTab = null" [style.background-color]="(selectedTab === 3) ? tabHoverColor : nonActiveTabColor"
        id="claim-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="claim"
        [style.color]="(selectedTab === 3) ? '#fff' : '#000'" [ngClass]="{ 'active': selectedTab === 3}">Claim</a>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div [class.active]="selectedTab === 1" class="tab-pane" id="quote" role="tabpanel" aria-labelledby="quote-tab">
      <div class="content-caption">{{quoteTabCaption}}</div>
      <app-quote [quoteWidget]="widget" [destinationSelection]="destinationSelection" [quoteButtonText]="quoteButtonText" [quoteButtonColor]="quoteButtonColor"
        [quoteButtonHoverColor]="quoteButtonHoverColor"></app-quote>
    </div>
    <div [class.active]="selectedTab === 2" *ngIf="showPolicyTab === true" class="tab-pane" id="manage" role="tabpanel" aria-labelledby="manage-tab">
      <div class="content-caption">{{manageTabCaption}}</div>
      <app-manage-policy (policyListingsReceived)="onPolicyListingsReceived($event)"></app-manage-policy>
    </div>
    <div [class.active]="selectedTab === 3" class="tab-pane" *ngIf="showClaimTab === true" id="claim" role="tabpanel" aria-labelledby="claim-tab">
      <div class="content-caption">{{claimTabCaption}}</div>
      <app-dynamic-button [compId]="'file-a-claim-btn'" (click)="onFileClaimLinkClicked()" [disabled]="buttonDisabled" [buttonHoverColor]="quoteButtonHoverColor" [buttonBackgroundColor]="quoteButtonColor">
        File a Claim&nbsp;&nbsp;
        <span class="fa fa-external-link" aria-hidden="true"></span>
      </app-dynamic-button>
      <app-dynamic-button [compId]="'track-your-claim-btn'" (click)="onTrackClaimLinkClicked()" [disabled]="buttonDisabled" [buttonHoverColor]="quoteButtonHoverColor" [buttonBackgroundColor]="quoteButtonColor">
        Check a Claim&nbsp;&nbsp;
        <span class="fa fa-external-link" aria-hidden="true"></span>
      </app-dynamic-button>
      <br/>
      <br/>
    </div>
  </div>
</div>
</mat-card>