import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.interface';
import * as fromAlert from './alert';

export * as fromShared from './alert';
export interface AlertState {
  alertType: fromAlert.State;
  message: fromAlert.State;
}

export interface State extends AppState {
  alertType: AlertState;
  message: AlertState;
}

export const reducers = {
  alertType: fromAlert.reducer,
  message: fromAlert.reducer
};

export const alertState = createFeatureSelector<AlertState>('shared');


// export const alertEntityState = createSelector(
//   alertState,
//   state => state.alert
// );

export const selectAlertType = createSelector(
  alertState,
  (state: AlertState) => state.alertType
);

export const getAlertType = createSelector(
  selectAlertType,
  fromAlert.getAlertType
);

export const selectMessage = createSelector(
  alertState,
  (state: AlertState) => state.message
);

export const getMessage = createSelector(
  selectMessage,
  fromAlert.getMessage
);

